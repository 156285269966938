<template>
  <form @submit.prevent="handleAction">
    <text-field
      v-model="form.name"
      :disabled="verificationPending"
      :error="nameError"
      :label="$t('name')"
      autocomplete="name"
      class="mb-5"
      data-cy="permanent-signup-name-field"
      required
    />
    <text-field
      v-model="form.email"
      :error="emailError"
      :label="$t('email')"
      autocomplete="email"
      class="mb-5"
      data-cy="permanent-signup-email-field"
      required
    />
    <text-field
      v-model="form.password"
      type="password"
      :bottom-hint="$t('new_password_requirement')"
      :disabled="verificationPending"
      :error="newPasswordError"
      :label="$t('new_password')"
      :placeholder="passwordPlaceholder()"
      autocomplete="new-password"
      class="mb-5"
      data-cy="permanent-signup-password-field"
      required
    />
    <text-field
      v-model="form.passwordConfirm"
      type="password"
      :disabled="verificationPending"
      :error="passwordConfirmError"
      :label="$t('password_confirm')"
      :placeholder="passwordPlaceholder()"
      autocomplete="password-confirm"
      class="mb-5"
      data-cy="permanent-signup-password-confirmation-field"
      required
    />
    <div class="flex flex-col items-center">
      <pill-button :disabled="submitDisabled" type="submit" class="mb-4 min-w-32">
        {{ verificationPending ? $t('update') : $t('submit') }}
      </pill-button>
    </div>
    <checkbox-field
      v-model="form.acceptedEmails"
      :label="$t('accepted_emails')"
      class="mb-4"
      :disabled="verificationPending"
    />
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import componentI18n from '@/i18n/componentI18n';
import constants from '@/constants';
import { EMAIL_REGEX } from '@/helpers/emails';

import router from '@/router';

export default {
  name: 'SignUpForm',
  ...componentI18n('components.sign_up_form'),
  props: {
    verificationPending: { type: Boolean, default: false }
  },
  data() {
    return {
      nameError: null,
      emailError: null,
      newPasswordError: null,
      passwordConfirmError: null,
      form: {
        name: '',
        email: '',
        password: '',
        passwordConfirm: '',
        acceptedEmails: true
      }
    };
  },
  computed: {
    ...mapGetters(['loggedIn', 'session']),
    submitDisabled() {
      return this.loggedIn && this.form.email === this.session.email;
    }
  },
  mounted() {
    if (this.loggedIn) {
      this.form.name = this.session.name;
      this.form.email = this.session.email;
    }
  },
  methods: {
    ...mapActions(['signUpUser', 'updateUserEmail']),
    async handleAction() {
      this.loggedIn ? this.handleUpdate() : this.handleSignUp();
    },
    async handleSignUp() {
      this.nameError = null;
      this.emailError = null;
      this.passwordConfirmError = null;
      this.newPasswordError = null;
      if (this.form.name.length === 0 || this.form.name.trim() === '') {
        this.nameError = this.$t('invalid_name');
        return;
      }
      if (!EMAIL_REGEX.test(this.form.email)) {
        this.emailError = this.$t('invalid_email');
        return;
      }
      if (this.form.password.length < constants.PASSWORD_MINIMUM_LENGTH) {
        this.newPasswordError = this.$t('new_password_too_short');
        return;
      }
      if (this.form.password !== this.form.passwordConfirm) {
        this.passwordConfirmError = this.$t('password_confirmation_mismatch');
        return;
      }
      const editedForm = { ...this.form, email: this.form.email.toLowerCase().trim() };
      const result = await this.signUpUser({
        ...editedForm,
        utmSource: this.utmSource()
      });
      if (result.success) {
        this.$emit('sign-up');
      } else if (result.message === 'email_taken') {
        this.emailError = this.$t('email_taken');
      }
    },
    async handleUpdate() {
      if (!EMAIL_REGEX.test(this.form.email)) {
        this.emailError = this.$t('invalid_email');
        return;
      }
      const result = await this.updateUserEmail({ email: this.form.email.toLowerCase().trim() });
      if (result.success) {
        router.push({ name: 'checkYourInbox' });
      } else if (result.message === 'email_taken') {
        this.emailError = this.$t('email_taken');
      }
    },
    passwordPlaceholder() {
      return this.loggedIn ? '*******' : null;
    },
    utmSource() {
      return this.$route.query.utm_source || localStorage.getItem('utmSource');
    }
  }
};
</script>
