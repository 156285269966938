import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { event } from 'vue-gtag';
import componentI18n from '@/i18n/componentI18n';
import router from '@/router';
import SignUpForm from '@/components/SignUpForm.vue';
export default defineComponent({
    name: 'SignUp',
    components: {
        SignUpForm
    },
    ...componentI18n('views.auth.sign_up'),
    data() {
        return {
            redirect: this.$attrs.redirect || '/',
            // TODO ADDED:
            // @ts-ignore
            requirePermanent: this.$route.params.requirePermanent
        };
    },
    computed: {
        ...mapGetters(['loggedIn', 'session']),
        subheading() {
            return this.requirePermanent ? this.$t('requires_permanent_users') : '';
        },
        verificationPending() {
            return this.loggedIn && !this.session.temporary && !this.session.verified;
        }
    },
    mounted() {
        if (this.loggedIn && !this.verificationPending) {
            router.push({ name: 'home' });
        }
    },
    methods: {
        handleSignup() {
            router.push({ name: 'checkYourInbox' });
            event('sign_up', {
                event_category: 'engagement',
                event_label: 'email'
            });
        },
        handleGoogleSignIn() {
            router.push(this.redirect);
            event('sign_up', {
                event_category: 'engagement',
                event_label: 'google'
            });
        }
    }
});
